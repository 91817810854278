import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
export const Index = ({ data }) => {
    const index = data.allMdx.nodes[0];
    return React.createElement(Layout, { current: "home" },
        React.createElement("div", { className: "content-slim" },
            React.createElement(MDXRenderer, null, index.body)));
};
export const query = graphql `
    query IndexMarkdownDocument {
        allMdx(filter: {frontmatter: {type: {eq: "index"}}}, limit: 1) {
            nodes {
                body
            }
        }
    }`;
export default Index;
