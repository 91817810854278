import React from "react";
import { Link } from "gatsby";
export const MenuLink = (props) => {
    return React.createElement(Link, { to: props.to, className: props.for === props.current ? "current" : undefined }, props.children);
};
export const Layout = (props) => {
    return React.createElement(React.Fragment, null,
        React.createElement("header", null,
            React.createElement("div", { className: "header" },
                React.createElement("div", { className: "logo" },
                    React.createElement(Link, { to: "/" }, "Codesmith")),
                React.createElement("div", { className: "menu" },
                    React.createElement("div", { className: "menupart" },
                        React.createElement(MenuLink, { to: "/", for: "home", current: props.current }, "home"),
                        React.createElement(MenuLink, { to: "/blog", for: "blog", current: props.current }, "blog"),
                        React.createElement(MenuLink, { to: "/contact", for: "contact", current: props.current }, "contact")),
                    React.createElement("div", { className: "menupart" },
                        React.createElement(MenuLink, { to: "/person", for: "person", current: props.current }, "person"),
                        React.createElement(MenuLink, { to: "/references", for: "references", current: props.current }, "references"))))),
        React.createElement("main", null, props.children));
};
